<template>
  <tp-modal
    v-if="order && order.id"
    max-width="1250px"
    name="modal-order"
    width="90%"
  >
    <v-card id="modal-order" class="text-body-1">
      <v-card-title>
        <div class="font-weight-bold">{{ modalTitle }} #{{ order.code }}</div>
        <v-chip
          :color="
            order.site === LIST_MODEL_SITES.GOODSPACE.id
              ? 'teal lighten-5 teal--text teal--lighten-1'
              : 'blue lighten-5 blue--text text--darken-4'
          "
          class="text-body-1 font-weight-bold px-2 py-2 ml-2"
          label
          small
        >
          {{
            order.site
              ? LIST_MODEL_SITES[order.site].text
              : LIST_MODEL_SITES.THINKPRO.text
          }}
        </v-chip>
        <v-chip
          v-if="order.cancel_status"
          class="text-body-2 font-weight-bold px-2 ml-2"
          color="red accent-2"
          outlined
          small
        >
          Đã hủy
        </v-chip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :href="order.link_web"
              class="cyan lighten-5 rounded-lg mr-2"
              color="primary"
              depressed
              icon
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-link-variant</v-icon>
            </v-btn>
          </template>

          <span>Link đặt hàng trên Web</span>
        </v-tooltip>

        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          depressed
          text
          @click="goToSalePage"
        >
          {{ order.cancel_status ? "Xem chi tiết" : "Chỉnh sửa" }}
        </v-btn>

        <v-btn
          v-if="order.id && order.payment_info && order.payment_info.length > 0"
          :to="{
            name: 'print_order-receipt-voucher',
            params: {
              orderId: order.id
            }
          }"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          depressed
          target="_blank"
          text
        >
          <v-icon left>mdi-printer</v-icon>
          In phiếu cọc
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <!-- Start: Cancel reason -->
        <v-card v-if="order.cancel_status === 1" class="mb-5" flat>
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <span class="font-weight-medium">
              Bị hủy bởi
              <span class="font-weight-bold">
                {{
                  order.canceled_by_user ? order.canceled_by_user.name : "N/A"
                }}
              </span>
            </span>
            <span v-if="order.canceled_reasons">
              <span class="font-weight-medium">với lý do:</span>
              {{ order.canceled_reasons }}
            </span>
          </div>
        </v-card>
        <!-- End: Cancel reason -->

        <card-general :order="order" />

        <card-customer :order="order" class="mt-5" />

        <card-products-detail
          class="mt-5"
          @isSelectedHandleTab="isSelectedHandleTab = $event"
        />

        <card-payment-transactions
          :isSelectedHandleTab="isSelectedHandleTab"
          class="mt-5"
        />

        <!-- Start: Note -->
        <v-card v-if="order.note" class="mt-5" flat>
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <strong>Ghi chú:</strong> {{ order.note }}
          </div>
        </v-card>
        <!-- End: Note -->
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { LIST_STATUS_ORDER } from "@/modules/Transaction/constant";

import CardCustomer from "./components/CardCustomer";
import CardGeneral from "./components/CardGeneral";
import CardPaymentTransactions from "./components/CardPaymentTransactions";
import CardProductsDetail from "./components/CardProductsDetail";
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  components: {
    CardCustomer,
    CardGeneral,
    CardPaymentTransactions,
    CardProductsDetail
  },

  data() {
    return {
      orderStatusList: LIST_STATUS_ORDER,
      LIST_MODEL_SITES,
      isSelectedHandleTab: false
    };
  },

  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabActiveIndex() {
      return this.$store.getters["SALE/saleTabActiveIndex"];
    },

    modalTitle() {
      if (this.order.order_type === "REPAIR") {
        return "Chi tiết đơn sửa chữa";
      }
      if (this.order.order_type === "GUARANTEE") {
        return "Chi tiết đơn bảo hành";
      }
      return "Chi tiết đơn hàng";
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-order" });
    },

    async goToSalePage() {
      const foundSaleTab = this.saleTabs.find(
        item => item.id === this.order.id
      );

      if (!foundSaleTab) {
        // Set or get data from local storage
        let localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

        await localStorage.setItem(
          "TPSALE_TAB_ACTIVE_INDEX",
          JSON.stringify(localSaleTabs.length)
        );

        await this.$store.dispatch("SALE/addSaleTabItem", {
          ...this.order
        });

        // Check order handles and set orderProcessing state
        await this.handleGetHandles(this.order);

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          localSaleTabs.length
        );
      }

      this.closeModal();
      // Push route
      await this.$router.push({
        name: "sale",
        query: {
          type: this.order.order_type,
          id: this.order.id
        }
      });
    },

    async handleGetHandles(item) {
      // Check order handles and set orderProcessing state
      if (item.handles.length > 0) {
        await this.$store.dispatch(
          "ORDER/getOrderProcessingById",
          item.handles[0].id
        );
        await this.$store.dispatch("SALE/setActiveOrderProcessingIndex", 0);
      } else {
        await this.$store.dispatch("ORDER/setOrderProcessing", []);
      }
    }
  }
};
</script>
