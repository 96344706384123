<template>
  <v-card flat>
    <v-row class="mx-0">
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Chi nhánh</div>
        <div class="py-1">
          {{ order.branch ? order.branch.name : "N/A" }}
        </div>
      </v-col>
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Nhân viên tạo</div>
        <div class="py-1">
          {{ order.created_by_user ? order.created_by_user.name : "N/A" }}
        </div>
      </v-col>
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Thời gian tạo</div>
        <div class="py-1">{{ order.created_at }}</div>
      </v-col>
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Chỉnh sửa cuối</div>
        <div class="py-1">{{ order.updated_at }}</div>
      </v-col>
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Trạng thái</div>
        <div>
          <v-chip
            class="text-body-2 font-weight-bold px-2"
            :color="getOrderStatus(order.status).color"
            outlined
            small
          >
            {{ getOrderStatus(order.status).text }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { getOrderStatusByValue } from "@/modules/Transaction/utils/common";

export default {
  props: {
    order: {
      type: Object,
      default: null
    }
  },

  methods: {
    getOrderStatus(value) {
      return getOrderStatusByValue(value);
    }
  }
};
</script>
