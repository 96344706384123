<template>
  <div class="relative">
    <v-overlay
      :value="
        orderProcessingStatusRequest.value === 'loading-getOrderProcessingById'
      "
      absolute
      color="white"
      :opacity="1"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>

    <handle-product-list></handle-product-list>

    <v-divider class="ma-3 mb-5"></v-divider>
  </div>
</template>

<script>
import HandleProductList from "./HandleProductList.vue";

export default {
  components: {
    HandleProductList
  },

  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    shippingInfo() {
      return this.$store.getters["ORDER_PROCESSING/shippingInfo"];
    },

    orderProcessingStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    }
  },

  methods: {
    async onViewSaleReceipt(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      await this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: id
        }
      );

      this.$modal.show({ name: "modal-sale-receipts" });
    }
  }
};
</script>
