var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order && _vm.order.id)?_c('tp-modal',{attrs:{"max-width":"1250px","name":"modal-order","width":"90%"}},[_c('v-card',{staticClass:"text-body-1",attrs:{"id":"modal-order"}},[_c('v-card-title',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.modalTitle)+" #"+_vm._s(_vm.order.code))]),_c('v-chip',{staticClass:"text-body-1 font-weight-bold px-2 py-2 ml-2",attrs:{"color":_vm.order.site === _vm.LIST_MODEL_SITES.GOODSPACE.id
            ? 'teal lighten-5 teal--text teal--lighten-1'
            : 'blue lighten-5 blue--text text--darken-4',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.order.site ? _vm.LIST_MODEL_SITES[_vm.order.site].text : _vm.LIST_MODEL_SITES.THINKPRO.text)+" ")]),(_vm.order.cancel_status)?_c('v-chip',{staticClass:"text-body-2 font-weight-bold px-2 ml-2",attrs:{"color":"red accent-2","outlined":"","small":""}},[_vm._v(" Đã hủy ")]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cyan lighten-5 rounded-lg mr-2",attrs:{"href":_vm.order.link_web,"color":"primary","depressed":"","icon":"","target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}}],null,false,3773725638)},[_c('span',[_vm._v("Link đặt hàng trên Web")])]),_c('v-btn',{staticClass:"cyan lighten-5 rounded-lg mr-2",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.goToSalePage}},[_vm._v(" "+_vm._s(_vm.order.cancel_status ? "Xem chi tiết" : "Chỉnh sửa")+" ")]),(_vm.order.id && _vm.order.payment_info && _vm.order.payment_info.length > 0)?_c('v-btn',{staticClass:"cyan lighten-5 rounded-lg mr-2",attrs:{"to":{
          name: 'print_order-receipt-voucher',
          params: {
            orderId: _vm.order.id
          }
        },"color":"primary","depressed":"","target":"_blank","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" In phiếu cọc ")],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"grey lighten-3 pa-5",attrs:{"fluid":""}},[(_vm.order.cancel_status === 1)?_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('div',{staticClass:"amber lighten-5 text-body-1 px-3 py-2"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Bị hủy bởi "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.canceled_by_user ? _vm.order.canceled_by_user.name : "N/A")+" ")])]),(_vm.order.canceled_reasons)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("với lý do:")]),_vm._v(" "+_vm._s(_vm.order.canceled_reasons)+" ")]):_vm._e()])]):_vm._e(),_c('card-general',{attrs:{"order":_vm.order}}),_c('card-customer',{staticClass:"mt-5",attrs:{"order":_vm.order}}),_c('card-products-detail',{staticClass:"mt-5",on:{"isSelectedHandleTab":function($event){_vm.isSelectedHandleTab = $event}}}),_c('card-payment-transactions',{staticClass:"mt-5",attrs:{"isSelectedHandleTab":_vm.isSelectedHandleTab}}),(_vm.order.note)?_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('div',{staticClass:"amber lighten-5 text-body-1 px-3 py-2"},[_c('strong',[_vm._v("Ghi chú:")]),_vm._v(" "+_vm._s(_vm.order.note)+" ")])]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }