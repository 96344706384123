<template>
  <div>
    <v-data-table
      class="datatable"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="order.details"
      item-key="id"
    >
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <template v-for="(item, index) in items">
            <!-- Start: Ordered product -->
            <tr :key="item.id">
              <td>{{ index + 1 }}</td>
              <!-- Start: Product info -->
              <td>
                <div class="my-2">
                  <div class="font-weight-bold">
                    {{ item.option.product_name }}
                  </div>
                  <div
                    class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
                  >
                    <span>{{ item.option.SKU }}</span>
                    <span v-if="item.option.name"> - </span>
                    <span
                      class="font-weight-light tag-p__mb-0"
                      v-html="item.option.name"
                    >
                    </span>
                  </div>
                </div>
              </td>
              <!-- End: Product info -->
              <!-- Start: Quantity -->
              <td>{{ item.qty }}</td>
              <!-- End: Quantity -->
              <!-- Start: Returned quantity -->
              <td class="text-center">
                {{ countReturnedQuantityOfOption(item.option_id) }}
              </td>
              <!-- End: Returned quantity -->
              <!-- Start: Price -->
              <td>{{ item.unit_price | formatCurrency }}</td>
              <!-- End: Price -->
              <!-- Start: Rebate -->
              <td>{{ item.rebate_amount | formatCurrency }}</td>
              <!-- End: Rebate -->
              <!-- Start: Sale price -->
              <td>
                {{ (item.unit_price - item.rebate_amount) | formatCurrency }}
              </td>
              <!-- End: Sale price -->
              <!-- Start: Total -->
              <td>
                {{
                  ((item.unit_price - item.rebate_amount) * item.qty)
                    | formatCurrency
                }}
              </td>
              <!-- End: Total -->
            </tr>
            <!-- End: Ordered product -->
            <!-- Start: Promotion products -->
            <tr
              v-for="(gift, indx) in item.gifts"
              :key="`gift-${indx}-${gift.option_id}`"
            >
              <td></td>
              <!-- Start: Product info -->
              <td>
                <div class="my-2">
                  <div class="font-weight-bold">
                    {{ gift.option.product_name }}
                  </div>
                  <div
                    class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
                  >
                    <span>{{ gift.option.SKU }}</span>
                    <span v-if="gift.option.name"> - </span>
                    <span
                      class="font-weight-light tag-p__mb-0"
                      v-html="gift.option.name"
                    >
                    </span>
                  </div>
                </div>
              </td>
              <!-- End: Product info -->
              <!-- Start: Quantity -->
              <td>
                {{ gift.qty }}
              </td>
              <!-- End: Quantity -->
              <!-- Start: Returned quantity -->
              <td class="text-center">
                {{
                  countReturnedQuantityOfGift(item.option_id, gift.option_id)
                }}
              </td>
              <!-- End: Returned quantity -->
              <!-- Start: Price -->
              <td>{{ gift.unit_price | formatCurrency }}</td>
              <!-- End: Price -->
              <!-- Start: Rebate -->
              <td>
                {{ gift.rebate_amount | formatCurrency }}
              </td>
              <!-- End: Rebate -->
              <!-- Start: Sale price -->
              <td>
                {{ (gift.unit_price - gift.rebate_amount) | formatCurrency }}
              </td>
              <!-- End: Sale price -->
              <!-- Start: Total -->
              <td>
                {{
                  ((gift.unit_price - gift.rebate_amount) * gift.qty)
                    | formatCurrency
                }}
              </td>
              <!-- End: Total -->
            </tr>
            <!-- End: Promotion products -->
          </template>
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="7">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>

    <v-divider class="ma-3"></v-divider>

    <div class="d-flex justify-end px-6 pb-5">
      <div class="block-summary">
        <div class="d-flex justify-space-between font-weight-medium">
          <span class="mr-5">Tổng tiền hàng: </span>
          <span>{{ orderGoodsTotal | formatCurrency }}</span>
        </div>

        <div
          v-if="order.shipping_fee"
          class="d-flex justify-space-between font-weight-medium"
        >
          <span class="mr-5">Phí vận chuyển: </span>
          <span>{{ order.shipping_fee | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium">
          <span class="mr-5">Phí thanh toán: </span>
          <span>{{ order.payment_fee | formatCurrency }}</span>
        </div>

        <!-- <div
          v-if="order.return_goods_value"
          class="d-flex justify-space-between font-weight-medium"
        >
          <span class="mr-5">Giảm trừ hàng trả: </span>
          <span>{{ order.return_goods_value | formatCurrency }}</span>
        </div> -->

        <!-- <div
          v-if="order.return_phitrahang"
          class="d-flex justify-space-between font-weight-medium"
        >
          <span class="mr-5">Phí trả hàng: </span>
          <span>{{ order.return_phitrahang | formatCurrency }}</span>
        </div> -->

        <div
          v-if="order.sc_fee"
          class="d-flex justify-space-between font-weight-medium"
        >
          <span class="mr-5">Phí sửa chữa: </span>
          <span>{{ order.sc_fee | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Giảm giá: </span>
          <span>-{{ order.discount | formatCurrency }}</span>
        </div>

        <div
          class="d-flex justify-space-between font-weight-bold mt-1 text-total"
        >
          <span class="mr-5">Khách phải trả: </span>
          <span>{{ order.value | formatCurrency }}</span>
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Khách thanh toán: </span>
          <span>{{ order.sum_paid | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Tiền hoàn trả khách: </span>
          <span>{{ order.pay_to_customer | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">(NET) khách thanh toán: </span>
          <span>{{
            (order.sum_paid - order.pay_to_customer) | formatCurrency
          }}</span>
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Khách còn nợ: </span>

          <span>{{ order.debt_customer | formatCurrency }}</span>
        </div>

        <!-- <v-divider class="my-2"></v-divider> -->

        <!-- <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Đơn trả hàng: </span>

          <div
            v-for="(item, index) in order.return_goods"
            :key="item.id"
            class="cursor-pointer primary--text"
          >
            <div @click="handleOpenReturnModal(item.id)">{{ item.code }}</div>
            <span v-if="index < order.return_goods.length - 1">,</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  countReturnedQuantityOfGift,
  countReturnedQuantityOfOption
} from "@/core/composables";

export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Số lượng đặt",
          align: "start",
          sortable: false,
          value: "qty"
        },
        {
          text: "Đã trả khách",
          align: "center",
          sortable: false,
          value: "returned_qty"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "Chiết khấu",
          align: "start",
          sortable: false,
          value: "rebate"
        },
        {
          text: "Giá sau giảm",
          align: "start",
          sortable: false,
          value: "sale_price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        }
      ]
    };
  },

  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    },
    orderGoodsTotal() {
      return this.$store.getters["ORDER/orderGoodsTotal"];
    },
    orderTotal() {
      return this.$store.getters["ORDER/orderTotal"];
    },
    orderSuccessfulThuTotal() {
      return this.$store.getters["ORDER/orderSuccessfulThuTotal"];
    },
    orderThuTotal() {
      return this.$store.getters["ORDER/orderThuTotal"];
    }
  },

  methods: {
    countReturnedQuantityOfGift(optionId, giftId) {
      return countReturnedQuantityOfGift(this.order, optionId, giftId);
    },

    countReturnedQuantityOfOption(optionId) {
      return countReturnedQuantityOfOption(this.order, optionId);
    },

    async handleOpenReturnModal(returnId) {
      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnById",
        returnId
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
