<template>
  <v-card flat>
    <v-overlay
      v-if="paymentTransactions.length"
      :value="
        orderStatusRequest.value === 'loading-getOrderPaymentTransactions' ||
          saleReceiptStatusRequest.value ===
            'loading-getSaleReceiptPaymentTransactions' ||
          orderStatusRequest.value === 'loading-getOrderProcessingById'
      "
      absolute
      color="white"
      :opacity="1"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>

    <div class="pa-5">
      <div>
        <div class="mb-2 d-flex font-weight-bold text-h6">
          <div>
            {{
              isSelectedHandleTab
                ? "Lịch sử thanh toán hoá đơn"
                : "Lịch sử thanh toán đơn hàng"
            }}
          </div>

          <div
            v-if="isSelectedHandleTab"
            class="ml-2 primary--text clickable-button"
            @click="openModalSaleReceiptDetail(orderProcessing.hoa_don_ban_id)"
          >
            {{ shortenCode(orderProcessing.hoa_don_ban_code) }}
          </div>
        </div>

        <list-payment-transaction
          :items="availablePaymentTransactions"
          :isShowTotal="false"
        ></list-payment-transaction>
      </div>

      <v-divider class="my-5"></v-divider>

      <div v-if="canceledPaymentTransactions.length">
        <div class="mb-2 font-weight-bold text-h6">Phiếu bị hủy</div>

        <list-payment-transaction
          :items="canceledPaymentTransactions"
          :isShowTotal="false"
        ></list-payment-transaction>
      </div>
    </div>
  </v-card>
</template>

<script>
import ListPaymentTransaction from "@/modules/Sale/pages/Order/pages/OrderPage/components/TabTransaction/components/ListPaymentTransaction.vue";
import { RECEIPT_STATUS } from "@/core/constant";

export default {
  components: {
    ListPaymentTransaction
  },

  props: {
    isSelectedHandleTab: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    paymentTransactions() {
      if (this.isSelectedHandleTab) {
        return this.$store.getters[
          "SALE_RECEIPT/saleReceiptPaymentTransactions"
        ];
      }
      return this.$store.getters["ORDER/orderPaymentTransactions"];
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    orderStatusRequest() {
      //getOrderPaymentTransactions
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleReceiptStatusRequest() {
      //getSaleReceiptPaymentTransactions
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },

    availablePaymentTransactions() {
      return this.paymentTransactions.filter(
        item => item.status !== RECEIPT_STATUS.BI_HUY
      );
    },

    canceledPaymentTransactions() {
      return this.paymentTransactions.filter(
        item =>
          item.status === RECEIPT_STATUS.BI_HUY ||
          item.status === RECEIPT_STATUS.DAO
      );
    }
  },

  methods: {
    shortenCode(code) {
      if (!code) return "";
      return code.split("-")[0];
    },

    async openModalSaleReceiptDetail(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);
      await this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      this.$modal.show({
        name: "modal-sale-receipts"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.clickable-button {
  cursor: pointer;
}

.clickable-button:hover {
  text-decoration: underline;
}
</style>
