<template>
  <v-card v-if="order.customer" flat>
    <v-row class="mx-0 align-center">
      <v-col class="pa-5">
        <div class="d-flex align-center w-max">
          <span class="font-weight-bold mr-1">Mã KH:</span>
          <span>{{ `KH${order.customer.id}` }}</span>
          <v-btn class="ml-1" color="primary" icon small>
            <v-icon
              size="18px"
              @click="copyToClipboard(`KH${order.customer.id}`, 'modal-order')"
              >mdi-content-copy
            </v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col class="pa-5" cols="auto">
        <div class="w-max">
          <span class="font-weight-bold">Tên KH: </span
          >{{ order.customer.name || "N/A" }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="d-flex align-center justify-center w-max">
          <span class="font-weight-bold pr-1">SĐT:</span>
          <template v-if="order.customer.phone">
            <span>
              {{ order.customer.phone | VMask("### ### ####") }}
            </span>
            <v-btn class="ml-1" color="primary" icon small>
              <v-icon
                size="18px"
                @click="copyToClipboard(order.customer.phone, 'modal-order')"
                >mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span v-else>N/A</span>
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="text-center w-max">
          <span class="font-weight-bold">Công nợ: </span>
          <span>
            <template v-if="order.customer.cong_no">
              {{ order.customer.cong_no | formatCurrency }}
            </template>
            <template v-else>N/A</template>
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped>
.w-max {
  width: max-content;
}
</style>
