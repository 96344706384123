var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.orderProcessing.details,"item-key":"id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:item.id,staticClass:"white"},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.option.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.option.SKU))]),(item.option.name)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"font-weight-light tag-p__mb-0"},[_vm._v(" "+_vm._s(item.option.name)+" ")])]),_c('div',_vm._l((item.serials),function(serial,index){return _c('v-tooltip',{key:index,attrs:{"color":"white","content-class":"elevation-4 px-2","disabled":_vm.serialTooltipCustom(serial).disabled,"bottom":"","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-2 mt-1 mr-1",class:_vm.serialTooltipCustom(serial).backgroundColor,attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(serial.serial_number)+" ")]),(_vm.orderProcessing.status < 4)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"icon-remove pl-1",attrs:{"color":hover
                              ? _vm.serialTooltipCustom(serial).iconHoverColor
                              : _vm.serialTooltipCustom(serial).iconColor,"small":""},on:{"click":function($event){return _vm.removeSerial(item, index)}}},[_vm._v(" mdi-close ")])]}}],null,true)}):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"black--text text-body-2 text-center",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(_vm.serialTooltipCustom(serial).text)+" ")])])}),1)])]),_c('td',{staticClass:"text-center"},[_c('div',[_c('div',[_vm._v(_vm._s(item.serials.length))])])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.unit_price))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.rebate_amount))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((item.unit_price - item.rebate_amount) * item.serials.length)))+" ")])]),_vm._l((item.gifts),function(gift,idx){return _c('tr',{key:("gift-" + (gift.id)),staticClass:"white"},[_c('td'),_c('td',[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(gift.option.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(gift.option.SKU))]),(gift.option.name)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"font-weight-light tag-p__mb-0"},[_vm._v(" "+_vm._s(gift.option.name)+" ")])]),_c('div',[(_vm.orderProcessing.status < 4)?_c('v-btn',{staticClass:"grey lighten-3 rounded px-0 mr-2 mt-1",attrs:{"color":"grey","depressed":"","height":"24px","icon":"","small":""},on:{"click":function($event){return _vm.openModalSerialPicker(gift)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e(),_vm._l((gift.serials),function(serial,serialIdx){return _c('v-tooltip',{key:serialIdx,attrs:{"color":"white","content-class":"elevation-4 px-2","bottom":"","disabled":_vm.serialTooltipCustom(serial).disabled,"nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-2 mt-1 mr-1",class:_vm.serialTooltipCustom(serial).backgroundColor,attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(serial.serial_number)+" ")]),(_vm.orderProcessing.status < 4)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var hover = ref.hover;
return [_c('v-icon',{staticClass:"icon-remove pl-1",attrs:{"color":hover
                              ? _vm.serialTooltipCustom(serial).iconHoverColor
                              : _vm.serialTooltipCustom(serial).iconColor,"small":""},on:{"click":function($event){return _vm.removeSerial(gift, idx)}}},[_vm._v(" mdi-close ")])]}}],null,true)}):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"black--text text-body-2 text-center",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(_vm.serialTooltipCustom(serial).text)+" ")])])})],2)])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(gift.serials.length))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(gift.unit_price))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(gift.rebate_amount))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((gift.unit_price - gift.rebate_amount) * gift.serials.length)))+" ")])])})]})],2):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }