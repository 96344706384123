<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="
      orderStatusRequest.value === 'loading-getOrderPaymentTransactions'
    "
    class="elevation-0"
    disable-pagination
    disable-sort
    hide-default-footer
    no-data-text="Không có dữ liệu"
    v-bind="$attrs"
  >
    <template v-slot:header.amount="{ header }">
      <div class="w-max-content text-center">
        <div>{{ header.text }}</div>
        <div>(Không tính phí TT)</div>
      </div>
    </template>
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Items -->
        <tr
          v-for="(item, index) in items"
          :key="item.id"
          :class="{ 'grey lighten-5': index % 2 === 0 }"
        >
          <td>
            <div>{{ item.created_at | formatTime("HH:mm") }}</div>
            <div>
              {{ item.created_at | formatTime("DD/MM/yyyy") }}
            </div>
          </td>
          <td>
            <div class="primary--text tp-btn--text">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="copied-label"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="copyToClipboard(item.code)"
                  >
                    {{ item.code }}
                  </button>
                </template>
                <span>Sao chép</span>
              </v-tooltip>
            </div>
          </td>
          <td>
            {{ getPaymentTypeText(item.type) }}
          </td>
          <td>
            <div v-if="item.senderable">
              <div>{{ item.senderable.name }}</div>
              <div>{{ item.senderable.receiver_bank }}</div>
            </div>

            <span v-else>N/A</span>
          </td>
          <td>
            <span v-if="item.receiverable">{{ item.receiverable.name }}</span>
            <span v-else>N/A</span>
          </td>
          <td>{{ getPaymentKindText(item.kind) }}</td>
          <td>
            <span v-if="item.receiver_bank">
              {{ item.receiver_bank.bank_account_label }} -
              {{ item.receiver_bank.account_bank }}
            </span>
            <span v-else>N/A</span>
          </td>

          <td class="text-center">
            {{ item.amount | formatCurrency }}
          </td>

          <td>
            <v-chip
              :color="getReceiptStatus(item.status).color"
              class="mr-2 font-weight-bold"
              outlined
              small
            >
              <v-progress-circular
                v-if="getReceiptStatus(item.status).isLoading"
                :color="getReceiptStatus(item.status).color"
                :size="15"
                :width="2"
                class="mr-2"
                indeterminate
              ></v-progress-circular>

              {{ item.status_label }}
            </v-chip>
          </td>

          <td>{{ item.explain || "N/A" }}</td>

          <td>
            <v-btn
              v-if="item.transactionable_type === 'App\\Models\\Order'"
              :disabled="
                item.status === RECEIPT_STATUS.DAO ||
                  item.status === RECEIPT_STATUS.BI_HUY ||
                  item.type === LIST_PAYMENT_TYPE.CHI.id
              "
              :to="{
                name: 'print_order-payment-transaction',
                params: {
                  paymentTransactionId: item.id
                }
              }"
              color="primary"
              depressed
              icon
              target="_blank"
              text
            >
              <v-icon size="20px">mdi-printer</v-icon>
            </v-btn>
          </td>
        </tr>
        <!-- End: Items -->
        <!-- Start: Total -->
        <tr v-if="isShowTotal" class="grey lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center font-weight-bold total-amount">
            {{ leftTotal | formatCurrency }}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <!-- Start: Total -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td :colspan="headers.length">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
import {
  getReceiptStatus,
  LIST_PAYMENT_KIND,
  LIST_PAYMENT_STATUS,
  LIST_PAYMENT_TYPE,
  RECEIPT_STATUS
} from "@/core/constant";
import {
  getPaymentKindText,
  getPaymentTypeText,
  showModalAlertError,
  showToastActionAlert
} from "@/core/composables";

export default {
  props: {
    items: {
      require: true,
      type: Array
    },
    isShowTotal: {
      default: true
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        { text: "Mã phiếu", value: "code" },
        { text: "Loại phiếu", value: "type" },
        { text: "Đối tượng chi", value: "senderable" },
        { text: "Đối tượng nhận", value: "receiverable" },
        { text: "Phương thức", value: "kind" },
        { text: "Tài khoản", value: "receiver_bank" },
        { text: "Số tiền", value: "amount" },
        { text: "Trạng thái", align: "left", value: "status" },
        { text: "Diễn giải", align: "left", value: "code" },
        { text: "", align: "center", value: "action" }
      ],
      LIST_PAYMENT_STATUS,
      LIST_PAYMENT_TYPE,
      RECEIPT_STATUS: RECEIPT_STATUS
    };
  },

  computed: {
    leftTotal() {
      const acceptableStatuses = [
        RECEIPT_STATUS.DOI_TAC_THU_HO_DA_THU_TIEN,
        RECEIPT_STATUS.CUA_HANG_DA_THU_TIEN,
        RECEIPT_STATUS.KET_TOAN,
        RECEIPT_STATUS.DA_THANH_TOAN
      ];

      const thuTotal = this.items
        .filter(
          item =>
            (item.type === LIST_PAYMENT_TYPE.THU.id ||
              item.type === LIST_PAYMENT_TYPE.THU_HO.id) &&
            acceptableStatuses.includes(item.status)
        )
        .reduce((total, item) => total + item.amount, 0);

      const chiTotal = this.items
        .filter(
          item =>
            item.type === LIST_PAYMENT_TYPE.CHI.id &&
            acceptableStatuses.includes(item.status)
        )
        .reduce((total, item) => total + item.amount, 0);

      return thuTotal - chiTotal;
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderPaymentTransactions() {
      return this.$store.getters["ORDER/orderPaymentTransactions"];
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    }
  },

  methods: {
    // TODO: Will be removed
    cancelPaymentTransaction(transaction) {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy giao dịch <strong>${transaction.code}</strong></span>`,
          description:
            "Bạn chỉ có thể hủy các phiếu đang Chờ xác nhận. " +
            "Riêng dối với phiếu thu Tiền mặt, bạn có thể hủy phiếu đã Thành công trong ngày. " +
            "Hành động hủy sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu bạn chắc chắn muốn tiếp tục:",
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch(
              "PAYMENT_TRANSACTION/cancelPaymentTransaction",
              {
                transactionId: transaction.id,
                note: cancelReason
              }
            );

            // Alert
            if (
              this.paymentTransactionStatusRequest.value ===
              "success-cancelPaymentTransaction"
            ) {
              // Alert
              showToastActionAlert(this, { message: "Đã hủy giao dịch" });

              // Get order payments
              await this.$store.dispatch(
                "ORDER/getOrderPaymentTransactions",
                this.order.id
              );
            } else if (
              this.paymentTransactionStatusRequest.value ===
              "error-cancelPaymentTransaction"
            ) {
              // Alert error
              showModalAlertError(this, {
                title: "Không thể hủy giao dịch",
                message: this.paymentTransactionStatusRequest.message.message
              });
            }
          }
        }
      });
    },

    checkDisableCancelTransaction(transaction) {
      if (transaction.kind === LIST_PAYMENT_KIND.TIEN_MAT.id) {
        return transaction.status === LIST_PAYMENT_STATUS.CANCEL.id;
      }

      return transaction.status !== LIST_PAYMENT_STATUS.PENDING.id;
    },

    getPaymentKindText(kind) {
      return getPaymentKindText(kind);
    },

    getReceiptStatus(status) {
      return getReceiptStatus(status);
    },

    getPaymentTypeText(type) {
      return getPaymentTypeText(type);
    }
  }
};
</script>

<style scoped>
.cursor-help {
  cursor: help;
}

.w-max-content {
  width: max-content;
}

.total-amount {
  position: relative;
}

.total-amount::before {
  content: "Tổng cộng";
  position: absolute;
  left: -75px;
}
</style>
