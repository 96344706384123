<template>
  <v-data-table
    class="table-scroll tp-table__row-pointer datatable"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="false"
    loading-text="Đang tải dữ liệu"
    :items="orderProcessing.details"
    item-key="id"
  >
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <template v-for="(item, index) in items">
          <!-- Start: Ordered product -->
          <tr class="white" :key="item.id">
            <td>{{ index + 1 }}</td>
            <!-- Start: Product info -->
            <td>
              <div class="my-2">
                <div class="font-weight-bold">
                  {{ item.option.product_name }}
                </div>
                <div
                  class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
                >
                  <span>{{ item.option.SKU }}</span>
                  <span v-if="item.option.name"> - </span>
                  <span class="font-weight-light tag-p__mb-0">
                    {{ item.option.name }}
                  </span>
                </div>
                <!-- Start: Serials -->
                <div>
                  <v-tooltip
                    v-for="(serial, index) in item.serials"
                    color="white"
                    content-class="elevation-4 px-2"
                    :disabled="serialTooltipCustom(serial).disabled"
                    bottom
                    :key="index"
                    nudge-top="5px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="px-2 mt-1 mr-1"
                        :class="serialTooltipCustom(serial).backgroundColor"
                        label
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="font-weight-medium">
                          {{ serial.serial_number }}
                        </span>
                        <v-hover
                          v-if="orderProcessing.status < 4"
                          v-slot:default="{ hover }"
                        >
                          <v-icon
                            :color="
                              hover
                                ? serialTooltipCustom(serial).iconHoverColor
                                : serialTooltipCustom(serial).iconColor
                            "
                            class="icon-remove pl-1"
                            small
                            @click="removeSerial(item, index)"
                          >
                            mdi-close
                          </v-icon>
                        </v-hover>
                      </v-chip>
                    </template>
                    <div
                      class="black--text text-body-2 text-center"
                      style="width: 180px"
                    >
                      {{ serialTooltipCustom(serial).text }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- End: Serials -->
              </div>
            </td>
            <!-- End: Product info -->
            <!-- Start: Quantity -->
            <td class="text-center">
              <div>
                <div>{{ item.serials.length }}</div>
              </div>
            </td>
            <!-- End: Quantity -->
            <!-- Start: Price -->
            <td class="text-center">
              {{ item.unit_price | formatCurrency }}
            </td>
            <!-- End: Price -->
            <!-- Start: Rebate -->
            <td class="text-center">
              {{ item.rebate_amount | formatCurrency }}
            </td>
            <!-- End: Rebate -->

            <!-- Start: Total -->
            <td class="text-center">
              {{
                ((item.unit_price - item.rebate_amount) * item.serials.length)
                  | formatCurrency
              }}
            </td>
            <!-- End: Total -->
          </tr>
          <!-- End: Ordered product -->
          <!-- Start: Promotion products -->
          <tr
            v-for="(gift, idx) in item.gifts"
            :key="`gift-${gift.id}`"
            class="white"
          >
            <td></td>
            <!-- Start: Product info -->
            <td>
              <div class="my-2">
                <div class="font-weight-bold">
                  {{ gift.option.product_name }}
                </div>
                <div
                  class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
                >
                  <span>{{ gift.option.SKU }}</span>
                  <span v-if="gift.option.name"> - </span>
                  <span class="font-weight-light tag-p__mb-0">
                    {{ gift.option.name }}
                  </span>
                </div>
                <!-- Start: Serials -->
                <div>
                  <v-btn
                    v-if="orderProcessing.status < 4"
                    class="grey lighten-3 rounded px-0 mr-2 mt-1"
                    color="grey"
                    depressed
                    height="24px"
                    icon
                    small
                    @click="openModalSerialPicker(gift)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>

                  <v-tooltip
                    v-for="(serial, serialIdx) in gift.serials"
                    color="white"
                    content-class="elevation-4 px-2"
                    bottom
                    :disabled="serialTooltipCustom(serial).disabled"
                    :key="serialIdx"
                    nudge-top="5px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="px-2 mt-1 mr-1"
                        :class="serialTooltipCustom(serial).backgroundColor"
                        label
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="font-weight-medium">
                          {{ serial.serial_number }}
                        </span>
                        <v-hover
                          v-if="orderProcessing.status < 4"
                          v-slot:default="{ hover }"
                        >
                          <v-icon
                            :color="
                              hover
                                ? serialTooltipCustom(serial).iconHoverColor
                                : serialTooltipCustom(serial).iconColor
                            "
                            class="icon-remove pl-1"
                            small
                            @click="removeSerial(gift, idx)"
                          >
                            mdi-close
                          </v-icon>
                        </v-hover>
                      </v-chip>
                    </template>
                    <div
                      class="black--text text-body-2 text-center"
                      style="width: 180px"
                    >
                      {{ serialTooltipCustom(serial).text }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- End: Serials -->
              </div>
            </td>
            <!-- End: Product info -->
            <!-- Start: Quantity -->
            <td class="text-center">{{ gift.serials.length }}</td>
            <!-- End: Quantity -->
            <!-- Start: Price -->
            <td class="text-center">
              {{ gift.unit_price | formatCurrency }}
            </td>
            <!-- End: Price -->
            <!-- Start: Rebate -->
            <td class="text-center">
              {{ gift.rebate_amount | formatCurrency }}
            </td>
            <!-- End: Rebate -->
            <!-- Start: Total -->
            <td class="text-center">
              {{
                ((gift.unit_price - gift.rebate_amount) * gift.serials.length)
                  | formatCurrency
              }}
            </td>
            <!-- End: Total -->
          </tr>
          <!-- End: Promotion products -->
        </template>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Số lượng",
          align: "center",
          sortable: false,
          value: "qty"
        },
        {
          text: "Đơn giá",
          align: "center",
          sortable: false,
          value: "unit_price"
        },
        {
          text: "Chiết khấu",
          align: "center",
          sortable: false,
          value: "rebate_amount"
        },
        {
          text: "Thành tiền",
          align: "center",
          sortable: false,
          value: "total"
        }
      ]
    };
  },
  computed: {
    branchSerialsList() {
      return this.$store.getters["MODAL_SERIAL_PICKER/branchSerialsList"];
    },
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    serialTooltipCustom(serial) {
      const res = {
        backgroundColor: "green lighten-5",
        iconColor: "green lighten-3",
        iconHoverColor: "primary",
        disabled: false
      };

      // Check disable cases
      if (
        serial.status === 1 &&
        serial.branch_id === this.orderProcessing.branch_id &&
        !serial.exchange_status
      ) {
        res.disabled = true;
      }

      // Check enable cases
      if (serial.status === 1) {
        // Case 1: In another branch and not delivery
        if (
          serial.exchange_status === 0 &&
          serial.branch_id !== this.orderProcessing.branch_id
        ) {
          res.backgroundColor = "red lighten-5";
          res.iconColor = "red lighten-3";
          res.iconHoverColor = "red accent-2";
          res.text = `Đang ở ${serial.branch_name}, chưa được chuyển`;
        }
        // Case 2: In another branch and not delivery
        if (serial.exchange_status === 1) {
          res.text = `Đang được chuyển đến ${serial.exchange_goods.branch_nhap_name} trong phiếu chuyển ${serial.exchange_goods.code}`;
          // Delivery to another branch
          if (
            serial.exchange_goods.branch_nhap_id !==
            this.orderProcessing.branch_id
          ) {
            res.backgroundColor = "orange lighten-5";
            res.iconColor = "orange lighten-3";
            res.iconHoverColor = "orange accent-2";
          }
          // Delivery to current branch
          if (
            serial.exchange_goods.branch_nhap_id ===
            this.orderProcessing.branch_id
          ) {
            res.backgroundColor = "cyan lighten-5";
            res.iconColor = "cyan lighten-3";
            res.iconHoverColor = "primary";
          }
        }
      }

      return res;
    },

    openModalSaleOrderPromotion() {
      this.$modal.show({ name: "modal-sale-order-promotion" });
    },

    async openModalSerialPicker(item) {
      // Set product option
      await this.$store.dispatch("MODAL_SERIAL_PICKER/setProductOption", {
        MPN: item.option.MPN,
        SKU: item.option.SKU,
        id: item.option.id,
        name: item.option.name,
        product_name: item.option.product_name
      });

      // Set branch
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranch",
        this.orderProcessing.branch_id
      );

      // Get branch serials list of product option
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/getBranchSerialsList",
        item.option.id
      );

      // Filter branch serials list by order processing branch
      const arr = this.branchSerialsList.filter(
        item => item.branch_id === this.orderProcessing.branch_id
      );
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setFilteredBranchSerialsList",
        arr
      );

      // Reset picked serials state
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setPickedSerials",
        item.serials
      );

      // Open modal
      this.$modal.show({
        name: "modal-serial-picker",
        payload: {
          onComplete: function(serials) {
            item.serials = serials;
          }
        }
      });
    },

    removeProductOption(item) {
      this.goodsDelivery.options.splice(this.indexOfOptionIds(item.id), 1);
    },

    removeSerial(item, index) {
      item.serials.splice(index, 1);
    }
  }
};
</script>

<style lang="scss">
.icon-remove {
  cursor: pointer;
  transition: all 0.4s ease;
}
.node-circle {
  border-radius: 100%;
  height: 7px;
  margin-bottom: 2px;
  width: 7px;
}
</style>
