var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.orderStatusRequest.value === 'loading-getOrderPaymentTransactions',"disable-pagination":"","disable-sort":"","hide-default-footer":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"w-max-content text-center"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('div',[_vm._v("(Không tính phí TT)")])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:item.id,class:{ 'grey lighten-5': index % 2 === 0 }},[_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at,"HH:mm")))]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.created_at,"DD/MM/yyyy"))+" ")])]),_c('td',[_c('div',{staticClass:"primary--text tp-btn--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyToClipboard(item.code)}}},'button',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentTypeText(item.type))+" ")]),_c('td',[(item.senderable)?_c('div',[_c('div',[_vm._v(_vm._s(item.senderable.name))]),_c('div',[_vm._v(_vm._s(item.senderable.receiver_bank))])]):_c('span',[_vm._v("N/A")])]),_c('td',[(item.receiverable)?_c('span',[_vm._v(_vm._s(item.receiverable.name))]):_c('span',[_vm._v("N/A")])]),_c('td',[_vm._v(_vm._s(_vm.getPaymentKindText(item.kind)))]),_c('td',[(item.receiver_bank)?_c('span',[_vm._v(" "+_vm._s(item.receiver_bank.bank_account_label)+" - "+_vm._s(item.receiver_bank.account_bank)+" ")]):_c('span',[_vm._v("N/A")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.amount))+" ")]),_c('td',[_c('v-chip',{staticClass:"mr-2 font-weight-bold",attrs:{"color":_vm.getReceiptStatus(item.status).color,"outlined":"","small":""}},[(_vm.getReceiptStatus(item.status).isLoading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"color":_vm.getReceiptStatus(item.status).color,"size":15,"width":2,"indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(item.status_label)+" ")],1)],1),_c('td',[_vm._v(_vm._s(item.explain || "N/A"))]),_c('td',[(item.transactionable_type === 'App\\Models\\Order')?_c('v-btn',{attrs:{"disabled":item.status === _vm.RECEIPT_STATUS.DAO ||
                item.status === _vm.RECEIPT_STATUS.BI_HUY ||
                item.type === _vm.LIST_PAYMENT_TYPE.CHI.id,"to":{
              name: 'print_order-payment-transaction',
              params: {
                paymentTransactionId: item.id
              }
            },"color":"primary","depressed":"","icon":"","target":"_blank","text":""}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-printer")])],1):_vm._e()],1)])}),(_vm.isShowTotal)?_c('tr',{staticClass:"grey lighten-5"},[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-center font-weight-bold total-amount"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.leftTotal))+" ")]),_c('td'),_c('td'),_c('td'),_c('td')]):_vm._e()],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Không có dữ liệu")])])])]}}])},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }