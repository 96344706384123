<template>
  <v-card class="px-3" flat>
    <v-tabs v-model="activeTab" class="pt-2" height="38px">
      <v-tab
        class="tab-custom text-body-1 font-weight-medium text-none"
        active-class="primary--text"
        style="letter-spacing: 0"
      >
        Chung
      </v-tab>

      <v-tab
        v-for="handle in order.handles"
        :key="handle.id"
        class="tab-custom text-body-1 font-weight-medium text-none"
        active-class="primary--text"
        style="letter-spacing: 0"
        @change="onTabChange(handle.id)"
      >
        {{ shortenHandlesCode(handle.code) }}

        <v-chip
          class="ml-2 white--text font-weight-bold rounded-lg cursor-pointer"
          :color="
            handle.cancel_status !== 1
              ? findOrderProcessingStatus(handle.status).color
              : 'red'
          "
          outlined
          small
        >
          {{
            handle.cancel_status !== 1
              ? findOrderProcessingStatus(handle.status).label
              : "Đã hủy"
          }}
        </v-chip>
      </v-tab>
    </v-tabs>

    <v-divider class="mb-2"></v-divider>

    <tab-order-general v-if="!activeTab"></tab-order-general>

    <tab-order-handle v-else></tab-order-handle>
  </v-card>
</template>

<script>
import {
  countReturnedQuantityOfGift,
  countReturnedQuantityOfOption
} from "@/core/composables";

import { findOrderProcessingStatus } from "@/modules/Transaction/constant";

import TabOrderGeneral from "./TabOrderGeneral.vue";
import TabOrderHandle from "./TabOrderHandle.vue";

export default {
  components: {
    TabOrderGeneral,
    TabOrderHandle
  },
  data() {
    return {
      activeTab: 0
    };
  },

  computed: {
    optionIds() {
      return this.order.details.map(item => item.id);
    },
    order() {
      return this.$store.getters["ORDER/order"];
    }
  },

  methods: {
    countReturnedQuantityOfGift(optionId, giftId) {
      return countReturnedQuantityOfGift(this.order, optionId, giftId);
    },

    countReturnedQuantityOfOption(optionId) {
      return countReturnedQuantityOfOption(this.order, optionId);
    },

    findOrderProcessingStatus(status) {
      return findOrderProcessingStatus(status);
    },

    async onTabChange(handleId) {
      const response = await this.$store.dispatch(
        "ORDER/getOrderProcessingById",
        handleId
      );

      await this.$store.dispatch(
        "SALE_RECEIPT/resetSaleReceiptPaymentTransactions"
      );

      if (response.hoa_don_ban_id) {
        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          response.hoa_don_ban_id
        );
      }

      this.$emit("isSelectedHandleTab", true);
    },

    shortenHandlesCode(code) {
      let arr = code.split("-");
      return arr[arr.length - 1];
    }
  },

  watch: {
    order: {
      handler() {
        this.activeTab = 0;
      },
      deep: true,
      immediate: true
    },

    activeTab(value) {
      if (value === 0) {
        this.$emit("isSelectedHandleTab", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.block-summary {
  max-width: 100%;
  width: 360px;
  .text-total {
    font-size: 1.125rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
